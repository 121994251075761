<template>
  <b-col md="6">
    <b-card no-body>
      <b-card-header>
        <b-card-title>منطقة الشحن للطلب</b-card-title>
      </b-card-header>
      <b-card-body>
        <template v-if="order.shipping_zone_id"> 
          <p>{{order.shipping_zone.name}}</p>
          <p>
            <b-button
              variant="outline-primary"
              size="sm"
              @click="change"
            >
              <feather-icon icon="EditIcon" />
              تعديل
            </b-button>
        </p>
        </template>
        <template v-else>
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('Select Shipping Zone')"
                label-for="v-shipping_zone_id"
              >
                <v-select
                  v-model="shipping_zone_id_selected"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="shippingZones"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            variant="success"
            size="sm"
            @click="send"
          >
            <template v-if="!isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>
        </template>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ShippingZoneOrder',
  directives: {
    Ripple,
  },
  props: ['order'],
  data() {
    return {
      isLoadingSave: false,
      shippingZones: [],
      shipping_zone_id_selected: null
    }
  },
  mounted() {
    this.getShippingZones()
  },
  methods: {
    send(){
      this.isLoadingSave = true
      useJwt.post('/shipping-zones/save-order',{order_id: this.order.id, shipping_zone_id: this.shipping_zone_id_selected.id})
      .then((response) => {
        this.isLoadingSave = false
        window.location.reload()
      })
      .catch(response => {
        this.isLoadingSave = false
        // console.log(response);
      });
    },
    change() {
      this.order.shipping_zone_id = null
    },
    getShippingZones(){
      useJwt.get('/shipping-zones/get-all',{store_id: this.$store.state.store.id})
      .then((response) => {
        // console.log(response.data)
        this.shippingZones = response.data.data
      })
      .catch(response => {
        // console.log(response);
      });
    },
  }
};

</script>
