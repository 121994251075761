<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
        <transition
          :name="$store.state.appConfig.layout.routerTransition"
          mode="out-in"
        >
          <div>
            <b-col md="12" class="p-0">
              <b-card v-if="Object.keys(order).length > 0">
                <!-- Header -->
                <b-card-body class="invoice-padding pb-0">

                  <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                    <!-- Header: Left Content -->
                    <div class="logo-wrapper">
                      <b-img :src="$store.state.store.logo" height="80" />
                    </div>

                    <!-- Header: Right Content -->
                    <div class="mt-md-0 mt-2">
                      <h3>
                        <span class="text-primary">#{{ order.id }}</span>
                      </h3>
                      <p>
                        {{ order.created_at | formatDate }}
                      </p>
                      <div v-if="order.status" class="no-print">
                        <h5>
                          <div v-if="order.status.id === 1 || order.status.id === 2 || order.status.id === 4">
                              <b-badge variant="danger" class="badge-glow my-btn">{{$t(order.status.name)}}</b-badge>
                          </div>
                          <div  v-else-if="order.status.id === 3">
                              <b-badge variant="warning" class="badge-glow my-btn">{{$t(order.status.name)}}</b-badge>
                          </div>
                          <div  v-else>
                              <b-badge variant="success" class="badge-glow my-btn">{{$t(order.status.name)}}</b-badge>
                          </div>
                        </h5>
                      </div>
                    </div>
                  </div>
                </b-card-body>

                <hr>

                <!-- Order Details -->
                <b-card-body
                  class="pt-0"
                >
                  <b-row>

                    <!--  -->
                    <b-col
                      cols="12"
                      xl="6"
                      class="p-0"
                    >
                      <h6 class="mb-1">
                        {{$t('Client Information')}}:
                      </h6>
                      <p class="mb-25">
                        <b-link :to="`/shoppers/`+order.shopper_id">
                          {{ order.username }}
                        </b-link>
                      </p>
                      <p class="mb-25">
                        <b-link :href="'https://wa.me/'+order.mobile" target="_blank">
                          {{order.mobile}}
                          <b-img
                              fluid
                              :src="require('@/assets/images/icons/whatsapp.png')"
                              alt="whatsapp"
                              width="15px"
                            />
                        </b-link>
                      </p>
                      <p class="mb-25">
                        {{ order.email }}
                      </p>
                      <p class="mb-25">
                        <b-img
                          :src="`https://cdn.mhd.sa/asset/images/providers/${order.payment_method.name_en}-logo.png`"
                          height="35"
                        />
                      </p>
                    </b-col>

                    <!-- Shipping Address -->
                    <b-col
                      xl="6"
                      cols="12"
                      class="p-0 mt-xl-0 mt-2 justify-content-xl-end"
                    >
                      <div>
                        <h6 class="mb-1">
                          {{$t('Shipping Address')}}:
                        </h6>
                        <table>
                          <tbody>
                            <tr>
                              <td class="pr-1">
                                <b>{{$t('Name')}}</b>
                              </td>
                              <td><span class="font-weight-bold">{{ order.address_owner }}</span></td>
                            </tr>
                            <tr>
                              <td class="pr-1">
                                <b>{{$t('City')}}</b>
                              </td>
                              <td>{{ order.city.name }}, {{ order.city.country.name }}</td>
                            </tr>
                            <tr>
                              <td class="pr-1">
                                <b>{{$t('Address')}}</b>
                              </td>
                              <td>
                                {{ order.address_details }}
                                <template v-if="order.latitude">
                                  <br>
                                  <a :href="`https://www.google.com/maps/search/?api=1&query=`+order.latitude+`,`+order.longitude" target="_blank" class="cursor-pointer">
                                    <feather-icon
                                      icon="MapPinIcon"
                                      size="13"
                                    />
                                    {{ $t('Open at map') }}
                                  </a>
                                  <br><br>
                                </template>
                              </td>
                            </tr>
                            <tr>
                              <td class="pr-1">
                                <b>{{$t('Shipping Method')}}</b>
                              </td>
                              <td>
                                {{ order.shippingMethodName }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <b-img
                                  :src="`https://cdn.mhd.sa/asset/images/providers/${order.shipping_method.name_en}-logo.png`"
                                  height="35"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>

                <!-- Order Items -->
                <b-card-body class="pb-0">
                  <b-row>
                    <h4>{{$t('Items')}}</h4>
                    <div class="table-responsive">
                      <table class="table b-table table-hover">
                        <thead>
                          <th>#</th>
                          <th>{{$t('Product Image')}}</th>
                          <th>{{$t('Item')}}</th>
                          <th>{{$t('Price')}}</th>
                          <th>{{$t('Quantity')}}</th>
                          <th>{{$t('Discount')}}</th>
                          <th>{{$t('Total')}}</th>
                        </thead>
                        <tbody>
                          <template v-for="(orderItem, index) in order.order_items">
                            <tr :key="index">
                              <td>{{index+1}}</td>
                              <td>
                                <template v-if="orderItem.orderable.media[0]">
                                  <b-img :src="orderItem.orderable.media[0].url" width="100" />
                                </template>
                              </td>
                              <td>
                                {{orderItem.orderable.name}}<br>
                                <small>{{orderItem.orderable.product_code}}</small>
                                <!-- Product Option -->
                                <template v-if="orderItem.intoProductOptions && orderItem.intoProductOptions.length > 0">
                                  <br><br>
                                  <h6>الخيارات:</h6>
                                  <template v-for="(productOption, po_i) in orderItem.intoProductOptions">
                                      <span :key="po_i">{{productOption.name}}</span>,
                                  </template>
                                </template>
                                <template v-if="orderItem.into_package != null">
                                  <br>
                                  <p>الأصناف في الباقة:</p>
                                  <template v-for="(product, p_i) in orderItem.intoPackageProducts">
                                    <p :key="p_i">{{product.name}}: {{product.quantity}}</p>
                                  </template>
                                </template>
                              </td>
                              <td>{{orderItem.unit_price}} {{$t('SAR')}}</td>
                              <td>{{orderItem.count}}</td>
                              <td>{{orderItem.discount}}</td>
                              <td>{{(orderItem.unit_price * orderItem.count) - orderItem.discount}} {{$t('SAR')}}</td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </b-row>
                </b-card-body>

                <!-- Total -->
                <b-card-body class="pb-0">
                  <dl class="row">
                    <dt class="col-sm-2 font-weight-bolder">
                      {{$t('Total Items')}}
                    </dt>
                    <dd class="col-sm-10">
                      {{order.items_price}} {{$t('SAR')}}
                    </dd>
                  </dl>
                  <dl class="row" v-if="order.discount > 0">
                    <dt class="col-sm-2 font-weight-bolder">
                      {{$t('Discount')}} ({{order.coupon_code}})
                    </dt>
                    <dd class="col-sm-10">
                      {{order.discount}} {{$t('SAR')}}
                    </dd>
                  </dl>
                  <dl class="row">
                    <dt class="col-sm-2 font-weight-bolder">
                      {{$t('Shipping Price')}}
                    </dt>
                    <dd class="col-sm-10">
                      {{order.shipping_price}} {{$t('SAR')}}
                    </dd>
                  </dl>
                  <dl class="row" v-if="order.payment_price > 0">
                    <dt class="col-sm-2 font-weight-bolder">
                      {{$t('COD Fee')}}
                    </dt>
                    <dd class="col-sm-10">
                      {{order.payment_price}} {{$t('SAR')}}
                    </dd>
                  </dl>
                  <dl class="row text-primary">
                    <dt class="col-sm-2 font-weight-bolder">
                      {{$t('Final Total')}}
                    </dt>
                    <dd class="col-sm-10">
                      {{order.total_price}} {{$t('SAR')}}
                    </dd>
                  </dl>
                </b-card-body>

              </b-card>
            </b-col>

            <!-- Blocks -->
            <b-row class="no-print">
              <b-col md="6">
                <b-card v-if="orderHistory.length > 0" no-body>
                  <b-card-header>
                    <b-card-title>{{$t('Order History')}}</b-card-title>
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      @click="$bvModal.show('updateStatusModal')"
                    >
                      <feather-icon
                        icon="PlusSquareIcon"
                        size="13"
                        class="cursor-pointer"
                      />
                      {{$t('Update Status')}}
                    </b-button>
                  </b-card-header>
                  <b-card-body>
                    <app-timeline>
                      <template v-for="(history, indexS) in orderHistory">
                        <app-timeline-item
                          :key="indexS"
                          :title="$t(history.title)"
                          :subtitle="history.subtitle"
                          :icon="history.icon"
                          :time="history.time | formatDate"
                          :variant="history.variant"
                        />
                      </template>
                    </app-timeline>
                  </b-card-body>
                </b-card>
              </b-col>
              <!-- <jones v-if="$store.state.store.id == 33" :shipment_details="order.shipment_details" :order_id="order.id"></jones> -->
              <aramex v-if="order.shipping_method_id == shippingMethodsIds.ARAMEX" :shipment_details="order.shipment_details" :order_id="order.id"></aramex>
              <barq-bullet v-if="order.shipping_method_id == shippingMethodsIds.BARQ_BULLET" :shipment_details="order.shipment_details" :order_id="order.id"></barq-bullet>
              <qoyod v-if="Object.keys(order).length > 0" :other_services_details="order.other_services_details" :order_id="order.id"></qoyod>
              <shipping-zone v-if="Object.keys(order).length > 0" :order="order"></shipping-zone>
              <!-- <torod :shipment_details="order.shipment_details" :order_id="order.id"></torod> -->
            </b-row>
          </div>
        </transition>
    </b-overlay>

    <!-- Update Status -->
    <b-modal
      id="updateStatusModal"
      modal-class="modal-primary"
      :title="$t('Update Order Status')"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <b-form @submit.prevent="updateOrderStatus">

        <!-- Status -->
        <b-form-group
          :label="$t('Status')"
          label-for="v-status"
        >
          <v-select
            v-model="updateStatus.status"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="statuses"
          />
        </b-form-group>

        <!-- Notes -->
        <b-form-group
          :label="$t('Notes')"
          label-for="v-notes"
        >
          <b-form-input 
            id="v-notes"
            v-model="updateStatus.notes"
            type="text"
          />
        </b-form-group>

        <!-- Submit -->
        <b-col cols="12" class="p-0 text-right">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            {{$t('Update')}}
            <template v-if="isLoadingSave">
              <b-spinner small />
            </template>
          </b-button>

        </b-col>
      </b-form>
    </b-modal>
   </div>
</template>
<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Jones from './Blocks/Jones.vue'
import Torod from './Blocks/Torod.vue'
import Aramex from './Blocks/Aramex.vue'
import BarqBullet from './Blocks/BarqBullet.vue'
import Qoyod from './Blocks/Qoyod.vue'
import ShippingZone from './Blocks/ShippingZone.vue'


export default {
  name: 'OrderShow',
  directives: {
    Ripple,
  },
  components: {
    AppTimeline,
    AppTimelineItem,
    Jones,
    Torod,
    Aramex,
    BarqBullet,
    Qoyod,
    ShippingZone
  },
  data() {
    return {
      order: {},
      isLoading: false,
      isLoadingSave: false,
      orderHistory: [],
      statuses: [],
      updateStatus: {
        notes: ''
      },
      shippingMethodsIds: {
        CUSTOMSHIPPING: 1,
        SMSA: 2,
        ARAMEX: 3,
        DHL: 4,
        BEEZ: 7,
        JONES: 10,
        BARQ_BULLET: 12,
        TOROD: 13
      }
    }
  },
  mounted() {
    this.getOrder();
    this.getStatuses();
  },
  methods: {
    getOrder(){
      this.isLoading = true
      useJwt.post('/orders/get-by-id',{id: this.$route.params.id})
      .then((response) => {
        // console.log(response.data.data)
        this.order = response.data.data

        this.order.order_history.forEach(orderHistory => {
          let icon, variant;
          switch(orderHistory.status_id) {
            case 1: // IGNORED
              icon = "XCircleIcon"
              variant = "secondary"
              break;
            case 2: // CANCELED
              icon = "XCircleIcon"
              variant = "secondary"
              break;
            case 3: // PENDING
              icon = "BookmarkIcon"
              variant = "warning"
              break;
            case 4: // PAYMENT_FAILED
              icon = "AlertCircleIcon"
              variant = "danger"
              break;
            case 5: // VALIDATING
              icon = "BookmarkIcon"
              variant = "secondary"
              break;
            case 6: // PREPARING
              icon = "PackageIcon"
              variant = "primary"
              break;
            case 7: // READY_TO_SHIP
              icon = "TruckIcon"
              variant = "info"
              break;
            case 8: // SHIPPING
              icon = "TruckIcon"
              variant = "info"
              break;
            case 9: // DELIVERED
              icon = "CheckCircleIcon"
              variant = "success"
              break;
            default:
              icon = "BookmarkIcon"
              variant = "secondary"
              break;
          }

          this.orderHistory.push({
            title: orderHistory.status.name_en,
            subtitle: orderHistory.notes,
            icon: icon,
            time: orderHistory.created_at,
            variant: variant,
          });


        });

        this.isLoading = false
      })
      .catch(response => {
        this.isLoading = false
        // console.log(response);
      });
    },
    updateOrderStatus(){
      this.isLoadingSave = true
      useJwt.post('/orders/add-order-history',{
        order_id: this.$route.params.id,
        status_id: this.updateStatus.status.id,
        notes: this.updateStatus.notes,
        store_id: this.$store.state.store.id
      })
      .then((response) => {
        this.isLoadingSave = false
        window.location.reload()
      })
      .catch(response => {
        this.isLoadingSave = false
        // console.log(response);
      });
    },
    getStatuses(){
      useJwt.get('/order-history/get-all-statuses')
      .then((response) => {
        // console.log(response);
        this.statuses = response.data.data
      })
      .catch(response => {
        // console.log(response);
      });
    }
  }
};

</script>
