<template>
  <b-col md="6">
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{$t('Qoyod')}}</b-card-title>
      </b-card-header>
      <b-card-body>
        <template v-if="other_services_details && other_services_details.length"> 
          <template v-for="(other_service_details, i) in other_services_details">
            <template v-if="other_service_details.other_service_id == 2">
              <p :key="i">رقم الفاتورة: {{other_services_details[0].reference_id}}</p>
            </template>
          </template>
        </template>
        <template v-else>
        <b-button
          variant="success"
          size="sm"
          @click="send"
        >
          <feather-icon
            icon="NavigationIcon"
            size="13"
            class="cursor-pointer"
          />
          <template v-if="!isLoadingSave">
            {{$t('Send To')}} {{$t('Qoyod')}}
          </template>
          <template v-if="isLoadingSave">
            {{$t('Sending To')}} {{$t('Qoyod')}}...
            <b-spinner small />
          </template>
        </b-button>
        </template>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'QoyodSendOrder',
  directives: {
    Ripple,
  },
  props: ['other_services_details', 'order_id'],
  data() {
    return {
      isLoadingSave: false,
    }
  },
  mounted() {
  },
  methods: {
    send(){
      this.isLoadingSave = true
      useJwt.get('/store/config/other-services/qoyod/send-order/'+this.order_id)
      .then((response) => {
        this.isLoadingSave = false
        window.location.reload()
      })
      .catch(response => {
        this.isLoadingSave = false
        // console.log(response);
      });
    }
  }
};

</script>
