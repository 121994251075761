<template>
  <b-col md="6">
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{$t('Aramex')}}</b-card-title>
      </b-card-header>
      <b-card-body>
        <template v-if="shipment_details.length"> 
          رقم الشحنة: {{shipment_details[0].tracking_number}}<br>
          <b-button
            variant="success"
            size="sm"
            @click="printLabel(shipment_details[0].id)"
          >
            <feather-icon
              icon="NavigationIcon"
              size="13"
              class="cursor-pointer"
            />
              بوليصة الشحن
              <template v-if="isLoading">
                <b-spinner small />
              </template>
          </b-button>
        </template>
        <template v-else>
        <b-button
          variant="success"
          size="sm"
          @click="send"
        >
          <feather-icon
            icon="NavigationIcon"
            size="13"
            class="cursor-pointer"
          />
          <template v-if="!isLoading">
            {{$t('Send To')}} {{$t('Aramex')}}
          </template>
          <template v-if="isLoading">
            {{$t('Sending To')}} {{$t('Aramex')}}...
            <b-spinner small />
          </template>
        </b-button>
        </template>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'AramexSendOrder',
  directives: {
    Ripple,
  },
  props: ['shipment_details', 'order_id'],
  data() {
    return {
      isLoading: false,
    }
  },
  mounted() {
  },
  methods: {
    send(){
      this.isLoading = true
      useJwt.get('/shipping-provider/aramex/send-order/'+this.order_id)
      .then((response) => {
        this.isLoading = false
        window.location.reload()
      })
      .catch(response => {
        this.isLoading = false
        // console.log(response);
      });
    },
    printLabel(id){
      this.isLoading = true
      useJwt.get('/shipping-provider/aramex/print-label/'+id)
      .then((response) => {
        this.isLoading = false
        window.location.href = response.data.data.url
      })
      .catch(response => {
        this.isLoading = false
        // console.log(response);
      });
    }
  }
};

</script>
